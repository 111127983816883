import React from "react";

function PythonIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8718 0.314697C8.84562 0.314697 9.4092 4.229 9.4092 4.229L9.41923 8.28434H18.0328V9.50186H5.99805C5.99805 9.50186 0.222168 8.84681 0.222168 17.9542C0.222168 27.0619 5.2635 26.7389 5.2635 26.7389H8.27215V22.5126C8.27215 22.5126 8.10996 17.4712 13.233 17.4712H21.7761C21.7761 17.4712 26.5759 17.5488 26.5759 12.8324V5.03414C26.5759 5.03414 27.3049 0.314697 17.8718 0.314697ZM13.1223 3.04183C13.3259 3.04165 13.5275 3.08161 13.7155 3.15941C13.9036 3.23722 14.0745 3.35136 14.2185 3.49528C14.3624 3.63921 14.4765 3.81011 14.5543 3.99819C14.6321 4.18628 14.6721 4.38786 14.6719 4.5914C14.6721 4.79495 14.6321 4.99653 14.5543 5.18462C14.4765 5.3727 14.3624 5.5436 14.2185 5.68753C14.0745 5.83145 13.9036 5.94559 13.7155 6.0234C13.5275 6.1012 13.3259 6.14116 13.1223 6.14098C12.9188 6.14116 12.7172 6.1012 12.5291 6.0234C12.341 5.94559 12.1701 5.83145 12.0262 5.68753C11.8823 5.5436 11.7681 5.3727 11.6903 5.18462C11.6125 4.99653 11.5726 4.79495 11.5728 4.5914C11.5726 4.38786 11.6125 4.18628 11.6903 3.99819C11.7681 3.81011 11.8823 3.63921 12.0262 3.49528C12.1701 3.35136 12.341 3.23722 12.5291 3.15941C12.7172 3.08161 12.9188 3.04165 13.1223 3.04183Z"
        fill="url(#paint0_linear_307_416)"
      />
      <path
        d="M18.1281 35.6854C27.1544 35.6854 26.5908 31.7711 26.5908 31.7711L26.5808 27.7159H17.9671V26.4984H30.0018C30.0018 26.4984 35.7777 27.1534 35.7777 18.0457C35.7777 8.93818 30.7364 9.26118 30.7364 9.26118H27.7277V13.4874C27.7277 13.4874 27.8899 18.5287 22.7668 18.5287H14.2237C14.2237 18.5287 9.42395 18.4512 9.42395 23.1677V30.9661C9.42395 30.9661 8.6951 35.6854 18.128 35.6854H18.1281ZM22.8777 32.9585C22.6741 32.9587 22.4725 32.9188 22.2845 32.841C22.0964 32.7632 21.9255 32.649 21.7815 32.5051C21.6376 32.3612 21.5235 32.1903 21.4457 32.0022C21.3679 31.8141 21.3279 31.6125 21.3281 31.409C21.3279 31.2054 21.3678 31.0038 21.4456 30.8157C21.5234 30.6276 21.6376 30.4567 21.7815 30.3128C21.9254 30.1688 22.0963 30.0547 22.2844 29.9768C22.4725 29.899 22.6741 29.8591 22.8777 29.8593C23.0812 29.8591 23.2828 29.899 23.4709 29.9768C23.659 30.0546 23.8299 30.1688 23.9738 30.3127C24.1177 30.4566 24.2319 30.6275 24.3097 30.8156C24.3875 31.0037 24.4274 31.2053 24.4272 31.4088C24.4274 31.6124 24.3875 31.814 24.3097 32.002C24.2319 32.1901 24.1177 32.361 23.9738 32.5049C23.8299 32.6489 23.659 32.763 23.4709 32.8408C23.2828 32.9186 23.0812 32.9586 22.8777 32.9584V32.9585Z"
        fill="url(#paint1_linear_307_416)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_307_416"
          x1="3.63904"
          y1="3.49607"
          x2="21.2593"
          y2="20.9405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#387EB8" />
          <stop offset="1" stopColor="#366994" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_307_416"
          x1="14.4543"
          y1="14.6979"
          x2="33.3765"
          y2="32.5856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE052" />
          <stop offset="1" stopColor="#FFC331" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PythonIcon;
