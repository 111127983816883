import React from "react";

export default function HtmlIcon() {
    return (
        <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.17456 32.2343L0.32251 0.222168H31.6778L28.8257 32.217L15.9742 35.7777" fill="#E44D26" />
            <path d="M16 33.0555V2.84973H28.8169L26.3711 30.1603" fill="#F16529" />
            <path d="M6.14771 6.76465H16.0002V10.6884H10.4517L10.8147 14.7072H16.0002V18.6223H7.21938L6.14771 6.76465ZM7.39224 20.5928H11.3333L11.6098 23.73L16.0002 24.9054V29.002L7.94536 26.7549" fill="#EBEBEB" />
            <path d="M25.8179 6.76465H15.9827V10.6884H25.4549L25.8179 6.76465ZM25.1006 14.7072H15.9827V18.6309H20.8225L20.3645 23.73L15.9827 24.9054V28.9847L24.0203 26.7549" fill="white" />
        </svg>
    )
}